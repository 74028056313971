import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  quotes: [],
  selectedQuote: null,
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.QUOTE_API_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.QUOTE_API_ERROR:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_QUOTES:
      newState.isLoading = false
      newState.quotes = action.payload
      return newState

    case ActionTypes.SUCCESS_GET_QUOTE:
      newState.isLoading = false
      newState.selectedQuote = action.payload
      return newState

    case ActionTypes.SUCCESS_CREATE_QUOTE:
      newState.isLoading = false
      newState.selectedQuote = action.payload
      return newState

    case ActionTypes.SUCCESS_SEND_QUOTE:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_QUOTE_REQUESTS:
      newState.isLoading = false
      newState.quotes = action.payload
      return newState

    case ActionTypes.SUCCESS_UPLOAD_FILE:
      newState.isLoading = false
      return newState

    default:
      return state
  }
}

export default reducer