import { useEffect, useRef } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const ProtectedRoutes = ({ isAuthenticated, isAllowed, redirectPath, applicationStatus = false }) => {
  const location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location.pathname }} />
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  if (applicationStatus && (location.pathname === '/application')) {
    return <Navigate to='/quote-requests' replace />
  }

  return <Outlet />
}

export default ProtectedRoutes
