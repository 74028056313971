import * as React from 'react'
import { Outlet } from 'react-router-dom'

import { styled } from '@mui/material/styles'

import Grid from '@mui/material/Grid'

import BackgroundSvg from '../../assets/images/auth-background.svg'
import LogoWhiteImg from '../../assets/images/logo-no-background.png'
import LogoImg from '../../assets/images/machine-shop-logo.svg'

const Wrapper = styled(Grid)({
  height: '100vh',
  overflow: 'auto',
})
const BackgroundImage = styled('img')({
  height: '100%',
  objectFit: 'cover',
})
const LogoImage = styled('img')({
  position: 'absolute',
  left: '50%',
  top: '50%',
  maxWidth: '100%',
  transform: 'translate(-50%, -50%)',
  objectFit: 'contain',
})
const FormWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})

const AuthLayout = () => {
  return (
    <Wrapper container>
      <Grid
        item
        md={4}
        lg="auto"
        sx={{ position: 'relative', overflow: 'hidden', display: { xs: 'none', md: 'block' } }}
      >
        <BackgroundImage src={BackgroundSvg} alt="UPPMODEL" />
        <LogoImage src={LogoImg} alt="UPPMODEL" />
      </Grid>
      <Grid item xs={12} md={8} lg sx={{ background: '#EFF3FC', px: { xs: 2, lg: 4 } }}>
        <FormWrapper>
          <Outlet />
        </FormWrapper>
      </Grid>
    </Wrapper>
  )
}

export default AuthLayout
