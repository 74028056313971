export default state => {
  return {
    ...state,
    auth: {
      ...state.auth,
      layoutRide: false,
      profileRide: false,
    },
  };
};
