import { createTheme } from '@mui/material/styles'

const grey = {
  100: '#FAFAFA',
  200: '#F4F4F8',
  300: '#E2E7EC',
  400: '#9FAEBB',
  500: '#717D87',
  600: '#546879',
  700: '#2D3843',
  800: '#182A39',
  900: '#133958',
}

/* eslint-disable */
const globalThemeOptions = {
  palette: {
    primary: {
      main: '#1761A0',
    },
    primaryShade: {
      main: '#E8EFF5',
      contrastText: '#1761A0',
    },
    primaryLight: {
      main: '#3E9AEA',
      contrastText: '#EAF2FA',
    },
    primaryLightShade: {
      main: '#EAF2FA',
      contrastText: '#3E9AEA',
    },
    secondary: {
      main: '#FCA452',
      contrastText: '#FFFFFF',
    },
    secondaryShade: {
      main: '#FFF6EE',
      contrastText: '#FCA452',
    },
    background: {
      default: '#F1F4F8',
      dark: '#133958',
    },
    text: {
      primary: '#383866',
      secondary: '#868699',
      drawer: '#BCDBF5',
    },
    info: {
      main: '#3699ff',
      contrastText: '#ffffff',
    },
    success: {
      main: '#19bc90',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ffa800',
      contrastText: '#ffffff',
    },
    error: {
      main: '#f64e60',
      contrastText: '#ffffff',
    },
    blue: {
      100: '#DAECFF',
      200: '#99CCF3',
      400: '#3399FF',
      500: '#007FFF',
      600: '#0072E5',
      900: '#003A75',
    },
    grey,
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: 'BiennaleRegular',
    fontWeightBold: 600,
    h1: {
      fontSize: 30,
      lineHeight: '40px',
      fontWeight: 700,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '34px',
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '26px',
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '22px',
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '18px',
    },
    h6: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
    },
    body1: {
      fontSize: 18,
      lineHeight: '30px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
    },
    caption: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '30px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: 'none',
          '&:hover,&:active': {
            boxShadow: 'none',
          },
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          borderColor: '#E2E7EC',
          borderWidth: 2,
          borderRadius: 8,
        },
        multiline: {
          padding: 0,
        },
        inputMultiline: {
          padding: '8px 12px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: grey[600],
          fontWeight: 'bold',
          fontSize: 11,
          letterSpacing: 1,
        },
        body: {
          color: grey[600],
          fontSize: 14,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(2n)': {
            background: grey[200],
          },
        },
      },
    },
  },
}

const theme = createTheme(globalThemeOptions)

export default theme
