export default state => {
  return {
    ...state,
    auth: {
      ...state.auth,
      email: '',
      password: '',
    },
  };
};
