import * as ActionTypes from '../utils/actionTypes'

import MachineShop from '../services/machineShop'
import { alertMsg } from '../utils/toast'
import { getUserProfile } from './auth'
import auth from '../reducers/auth'

export const machineShopLoading = () => ({
  type: ActionTypes.MACHINE_SHOP_API_LOADING,
})

export const machineShopError = error => ({
  type: ActionTypes.MACHINE_SHOP_API_ERROR,
  payload: error,
})

export const createMachineShop = (data, navigate) => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    const { user } = getState().auth
    try {
      const result = await MachineShop.createMachineShop(data)

      dispatch({
        type: ActionTypes.AUTH_SUCCESS_CREATE_MACHINE_SHOP,
        payload: result.id,
      })
      dispatch({
        type: ActionTypes.SUCCESS_CREATE_MACHINE_SHOP,
        payload: result,
      })
      const statusResult = await MachineShop.getMachineShopStatus()
      dispatch({
        type: ActionTypes.SUCCESS_GET_MACHINE_SHOP_STATUS,
        payload: statusResult,
      })

      navigate('/profile')
      alertMsg('success', 'Application Sent Successfully')
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const updateMachineShop = (data, navigate) => {
  return async (dispatch, getState) => {
    try {
      const result = await MachineShop.updateMachineShop(data)
      dispatch({
        type: ActionTypes.SUCCESS_UPDATE_MACHINE_SHOP,
        payload: result,
      })
      alertMsg('success', 'Shop Updated Successfully')
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const getMachineShopCapabilityList = () => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.getMachineShopCapabilityList()
      dispatch({
        type: ActionTypes.SUCCESS_MACHINE_SHOP_CAPABILITY_LIST,
        payload: result,
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const getMachineShopCertificationList = () => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.getMachineShopCertificationList()
      dispatch({
        type: ActionTypes.SUCCESS_MACHINE_SHOP_CERTIFICATION_LIST,
        payload: result,
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const getMachineShops = () => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.getMachineShops()
      dispatch({
        type: ActionTypes.SUCCESS_GET_MACHINE_SHOPS,
        payload: result,
      })
      if (result && result.length > 0) {
        result.forEach(machineShop => {
          dispatch(getAddressForMachineShop(machineShop.addressId))
        })
      }
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const setSelectedShop = shop => ({
  type: ActionTypes.SET_SELECTED_MACHINE_SHOP,
  payload: shop,
})

export const assignMachineShopID = (userID, machineShopID, navigate) => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.assignMachineShopID(userID, machineShopID)
      dispatch({
        type: ActionTypes.SUCCESS_ASSIGN_MACHINE_SHOP_ID,
        payload: result,
      })
      dispatch(getUserProfile(true))
      navigate(-1)
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const approveMachineShop = (creator, id, navigate) => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.approveMachineShop(id)
      dispatch({
        type: ActionTypes.SUCCESS_APPROVE_MACHINE_SHOP,
        payload: result,
      })
      dispatch(assignMachineShopID(creator, id, navigate))
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const getAddressForMachineShop = addressId => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.getAddressForMachineShop(addressId)
      dispatch({
        type: ActionTypes.SUCCESS_GET_ADDRESS,
        payload: result,
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const setApplicationData = (fieldName, value) => ({
  type: ActionTypes.SET_APPLICATION_DATA,
  payload: {
    fieldName,
    value,
  },
})

export const getMachineShop = id => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.getMachineshop(id)
      dispatch({
        type: ActionTypes.SUCCESS_GET_MACHINE_SHOP,
        payload: result,
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const getMachineShopPolling = id => {
  return async (dispatch, getState) => {
    try {
      const result = await MachineShop.getMachineshop(id)
      dispatch({
        type: ActionTypes.SUCCESS_GET_MACHINE_SHOP,
        payload: result,
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const connectBank = (machineShopId, cb) => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.connectBank(machineShopId)
      dispatch({
        type: ActionTypes.SUCCESS_BANK_CONNECT,
        payload: result,
      })
      await cb(result)
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const checkStatusBankConnection = (machineShopId, cb) => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.checkStatusBankConnection(machineShopId)
      dispatch({
        type: ActionTypes.SUCCESS_CHECK_STATUS_BANK_CONNECTION,
        payload: result,
      })
      await cb(result)
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const disconnectBank = machineShopId => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.disconnectBank(machineShopId)
      dispatch({
        type: ActionTypes.SUCCESS_BANK_DISCONNECT,
        payload: result,
      })
      dispatch(getMachineShop(machineShopId))
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}

export const getMachineShopStatus = () => {
  return async (dispatch, getState) => {
    dispatch(machineShopLoading())
    try {
      const result = await MachineShop.getMachineShopStatus()
      dispatch({
        type: ActionTypes.SUCCESS_GET_MACHINE_SHOP_STATUS,
        payload: result,
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(machineShopError(err))
    }
  }
}
