export default state => {
  return {
    ...state,
    auth: {
      isLoading: false,
      isAuthenticated: false,
      token: null,
      refreshToken: null,
      expiresIn: 0,
      user: null,
      error: null,
      company: null,
      companyInfo: null,
      clientAddress: null,
      layoutRide: false,
      profileRide: false,
      isUnAuthError: false,
    },
  };
};
