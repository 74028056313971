import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  partRequests: [],
  selectedPartRequest: null,
  partRequestsDetail: [],
  partRequestForQuoteRequests: [],
  openPartRequests: [],
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.PART_REQUEST_API_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.PART_REQUEST_API_ERROR:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_PART_REQUESTS:
      newState.isLoading = false
      newState.partRequests = action.payload
      return newState

    case ActionTypes.SUCCESS_GET_PART_REQUEST_DETAIL:
      newState.isLoading = false
      newState.partRequestsDetail = action.payload
      return newState

    case ActionTypes.SUCCESS_GET_PART_REQUEST:
      newState.isLoading = false
      newState.selectedPartRequest = action.payload
      return newState

    case ActionTypes.SUCCESS_CREATE_PART_BID:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_DOWNLOAD_FILE:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_ACKNOWLEDGE_QUOTE_REQUEST:
      newState.isLoading = false
      return newState

    case ActionTypes.CLEAR_PART_REQUEST:
      newState.partRequestForQuoteRequests = []
      return newState

    case ActionTypes.SUCCESS_GET_QUOTE_PART_REQUESTS:
      newState.isLoading = false
      let copiedPartRequestForQuoteRequests = [...newState.partRequestForQuoteRequests]
      if (action.payload && action.payload.length > 0) {
        action.payload.forEach(partRequestForQuoteRequest => {
          const availableItem = copiedPartRequestForQuoteRequests.find(
            item => item.id === partRequestForQuoteRequest.id,
          )
          if (!availableItem) {
            copiedPartRequestForQuoteRequests.push(partRequestForQuoteRequest)
          }
        })
      }
      newState.partRequestForQuoteRequests = copiedPartRequestForQuoteRequests
      return newState

    case ActionTypes.TOGGLE_PART_REQUESTS:
      newState.openPartRequests[action.payload.order] = action.payload.value
      return newState

    default:
      return state
  }
}

export default reducer
