import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import Blur from 'react-css-blur'
import usePoll from 'react-use-poll'

import { Typography, Button } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

import Loader from '../Loader/Loader'

import Sidebar from './Sidebar'
import keyIcon from '../../assets/icons/key.svg'

import { getMachineShop, getMachineShopPolling } from '../../actions/machineShop'
import { login, logout } from '../../actions/auth'

const Layout = () => {
  const { isLoading, isRefreshLoading, isUnAuthError, user } = useSelector(state => state.auth)
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useDeepCompareEffect(() => {
    if (user && (user.roles.indexOf('Machinist') > -1 || user.roles.indexOf('machinist') > -1)) {
      if (user.machineShopId && user.machineShopId) {
        dispatch(getMachineShop(user.machineShopId))
      }
    }
  }, [user])

  usePoll(
    () => {
      if (user && (user.roles.indexOf('Machinist') > -1 || user.roles.indexOf('machinist') > -1)) {
        if (user.machineShopId && user.machineShopId) {
          // dispatch(getMachineShopPolling(user.machineShopId))
        }
      }
    },
    [user],
    {
      interval: 10000,
    },
  )

  const isSidebar = () => {
    return !(pathname?.indexOf('/checkout') > -1 || pathname?.indexOf('/purchase-order') > -1)
  }

  if (isRefreshLoading) {
    return <Loader loading={true} global />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        background: '#e7e7e7',
        overflowX: 'hidden',
      }}
    >
      {isSidebar() && <Sidebar />}
      <Box
        component="main"
        sx={{
          width: '100%',
          pt: user ? 0 : 10,
          px: user ? 0 : 3,
          pb: user ? 0 : 6,
          background: '#e7e7e7',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default Layout
