import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { store } from '../store'
import { refreshAccessToken, logout } from '../actions/auth'
import { REQUEST_CONSTANTS } from './constants'
import { VERSION_URL } from '../services/urls'

const defaultHeader = {
  Accept: '*/*',
  'Content-Type': 'application/json'
}

const getAccessToken = () => {
  return store.getState().auth.token
}

const Api = axios.create({
  baseURL: VERSION_URL,
  headers: defaultHeader
})

Api.interceptors.request.use(
  (config) => {
    const token = getAccessToken()
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

createAuthRefreshInterceptor(
  Api,
  () => Promise.resolve(store.dispatch(refreshAccessToken()))
)

export { Api }
