import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  shops: [],
  selectedShops: [],
  addresses: [],
  filters: [],
  filters_2: [],
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.SHOP_API_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.SHOP_API_ERROR:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_SHOPS:
      newState.isLoading = false
      newState.shops = action.payload
      return newState

    case ActionTypes.SET_SELECTED_SHOP:
      newState.selectedShops = action.payload
      return newState

    case ActionTypes.CLEAR_SELECTED_SHOP:
      newState.selectedShops = []
      return newState

    case ActionTypes.SUCCESS_GET_ADDRESS_FOR_SHOP:
      newState.isLoading = false
      let copiedAddresses = [...newState.addresses]
      if (!copiedAddresses.find(item => item.id === action.payload.id)) {
        copiedAddresses.push(action.payload)
      }
      newState.addresses = copiedAddresses
      return newState

    case ActionTypes.SET_SHOP_FILTERS_BY_SERVICE:
      newState.filters = action.payload
      return newState

    case ActionTypes.SET_SHOP_FILTERS_BY_SERVICE_2:
      newState.filters_2 = action.payload
      return newState

    default:
      return state
  }
}

export default reducer