import migration2 from './2'
import migration3 from './3'
import migration4 from './4'
import migration5 from './5'
import migration6 from './6'
import migration7 from './7'
import migration8 from './8'
import migration9 from './9'
import migration10 from './10'
import migration11 from './11'
import migration12 from './12'
import migration13 from './13'

export const CurrentVersion = 13

export default {
  2: migration2,
  3: migration3,
  4: migration4,
  5: migration5,
  6: migration6,
  7: migration7,
  8: migration8,
  9: migration9,
  10: migration10,
  11: migration11,
  12: migration12,
  13: migration13,
}
