import * as REQUESTS from './request'
import * as URL from './urls'

/**
 * request methods
 */
const { GET, POST, DELETE, PUT, PATCH } = REQUESTS

class ConversationService {

  // static getConversations = async () => {
  //   return GET(URL.CREATE_PROJECT)
  // }

  static getConversations = async (params) => {
    return GET(URL.GET_CONVERSATIONS, {}, params)
  }

  static getConversation = async (conversationId) => {
    return GET(`${URL.GET_CONVERSATIONS}/${conversationId}`)
  }

  static deleteConversation = async (conversationId) => {
    return DELETE(`${URL.GET_CONVERSATIONS}/${conversationId}`)
  }
}

export default ConversationService