import * as ActionTypes from '../utils/actionTypes'
import ConversationService from "../services/conversation"

export const conversationLoading = () => ({
    type: ActionTypes.CONVERSATION_API_LOADING,
  })

  export const conversationError = error => ({
    type: ActionTypes.PROJECT_API_ERROR,
    payload: error,
  })


export const getConversations = async () => {
  try {
    const result = await ConversationService.getConversations()
    return result
  } catch (err) {
    console.log(`Error :: ${err}`)
  }
}
