import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'

import NavItem from './NavItem'

import navigation, { admins, clients, machinists } from './navigation'

import { Drawer, DrawerHeader, DrawerToggle } from './styles'
import SidebarLogoImg from '../../assets/images/sidebar/sidebar-logo.svg'
import SidebarMenuImg from '../../assets/images/sidebar/sidebar-menu.svg'

import { getMachineShopStatus } from '../../actions/machineShop'
import { getConversations } from '../../actions/conversation'
import { Client as ConversationsClient } from '@twilio/conversations'
import ChatReadIcon from '../../assets/images/sidebar/chat-read.svg'
import ChatUnreadIcon from '../../assets/images/sidebar/chat-unread.svg'
import LogoutIcon from '../../assets/images/sidebar/logout.svg'
import ProfileIcon from '../../assets/images/sidebar/profile.svg'
import UserIcon from '../../assets/images/sidebar/user.svg'

const MiniDrawer = () => {
  const [open, setOpen] = useState(true)
  const { user, layoutRide } = useSelector(state => state.auth)
  const { status } = useSelector(state => state.machineShop)
  const [hasMissedMessages, setHasMissedMessages] = useState(false)
  const dispatch = useDispatch()

  const checkForMissedMessages = async () => {
    const convoRes = await getConversations()
    const conversationsClient = new ConversationsClient(convoRes.token)

    const cxs = await conversationsClient.getSubscribedConversations()
    for (const cx of cxs.items) {
      const participants = cx.participants
      for (const p of participants.values()) {
        if (p.state.identity === convoRes.identity) {
          const lastmsg = cx.channelState.lastMessage?.index ?? 0
          const unreadMessages = p.state.lastReadMessageIndex < lastmsg
          if (!!unreadMessages) {
            setHasMissedMessages(true)
          }
        }
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      await checkForMissedMessages()
    }, 10000) // Check every 2 minutes

    return () => clearInterval(interval) // Clean up the interval on component unmount
  }, [])

  useEffect(() => {
    dispatch(getMachineShopStatus())
  }, [status])

  const handleToggleDrawer = () => {
    setOpen(prev => !prev)
  }

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        {open && (
          <Box
            component="img"
            src={SidebarLogoImg}
            alt="uppmodel"
            sx={{ objectFit: 'contain', maxWidth: 'calc(100% - 80px)' }}
          />
        )}
        <DrawerToggle onClick={handleToggleDrawer}>
          {/* <MenuIcon htmlColor="#BCDBF5" /> */}
          <Box component="img" src={SidebarMenuImg} alt="uppmodel" sx={{ objectFit: 'contain', width: '26px' }} />
        </DrawerToggle>
      </DrawerHeader>
      <Divider />
      <List>
        <NavItem
          key={'profile'}
          item={{
            label: 'Profile',
            path: '/profile',
            icon: UserIcon,
            children: [
              {
                label: 'Profile',
                path: '/profile',
                icon: ProfileIcon,
                color: '#FCA452',
              },
              {
                label: 'Log Out',
                path: '/logout',
                icon: LogoutIcon,
              },
            ],
          }}
          shrink={open}
          isExpanded={open}
        />
        {(user && user.roles.indexOf('Admin') > -1) ||
          (user.roles.indexOf('admin') > -1 && (
            <>
              {admins.map(item => (
                <NavItem key={item.path} item={item} shrink={open} isExpanded={open} />
              ))}
            </>
          ))}
        {(user && user.roles.indexOf('Client') > -1) ||
          (user.roles.indexOf('client') > -1 && (
            <>
              {clients.map(item => (
                <NavItem key={item.path} item={item} shrink={open} isExpanded={open} />
              ))}
            </>
          ))}
        {(user && user.roles.indexOf('Machinist') > -1) ||
          (user.roles.indexOf('machinist') > -1 && (
            <>
              {machinists.map(item => {
                if (item.path === '/application' && status && status !== 'approved' && status !== 'pending') {
                  return <NavItem key={item.path} item={item} isExpanded={open} />
                } else if (item.path !== '/application') {
                  return <NavItem key={item.path} item={item} isExpanded={open} />
                }
                return
              })}
            </>
          ))}
        {!hasMissedMessages && (
          <NavItem
            key={'/messages'}
            item={{
              label: 'Messages',
              path: '/messages',
              icon: ChatReadIcon,
            }}
            isExpanded={open}
          />
        )}
        {!!hasMissedMessages && (
          <NavItem
            key={'/messages'}
            item={{
              label: 'Messages',
              path: '/messages',
              icon: ChatUnreadIcon,
            }}
            isExpanded={open}
          />
        )}
      </List>
    </Drawer>
  )
}

export default MiniDrawer
