import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  parts: [],
  selectedPart: null,
  files: []
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.PART_API_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.PART_API_ERROR:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_UPDATE_PART:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_PART:
      newState.selectedPart = action.payload
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_PARTS:
      newState.parts = action.payload
      if (!action.payload || action.payload?.length === 0) {
        newState.files = []
        newState.selectedPart = null
      }
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_FILE:
      newState.isLoading = false
      let copiedFiles = [...state.files]
      if (copiedFiles && copiedFiles.length > 0) {
        if (!(copiedFiles.find(file => file.id === action.payload.id))) {
          copiedFiles.push(action.payload)
        }
      } else {
        copiedFiles.push(action.payload)
      }
      newState.files = copiedFiles
      return newState

    default:
      return state
  }
}

export default reducer