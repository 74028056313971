import { useCallback, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'

import ArrowUp from '../../assets/images/sidebar/arrow-up.svg'
import ArrowDown from '../../assets/images/sidebar/arrow-down.svg'

import { logout, passRide } from '../../actions/auth'

const MyRootBox = styled(Box)(({ theme, open, isExpanded }) => {
  return {
    marginLeft: '19px',
    marginRight: '13px',
    borderRadius: '20px',
    background: open ? 'linear-gradient(90deg, rgba(76, 150, 215, 0.1) 0%, rgba(76, 150, 215, 0) 100%)' : 'transparent',
  }
})

const MyRootListItemButton = styled(ListItemButton, { shouldForwardProp: prop => prop !== 'isActive' && prop !== 'isExpanded' })(({ isActive, isExpanded }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px 0',
    background: isActive ? 'linear-gradient(90deg, rgba(76, 165, 215, 0.7) 0%, rgba(76, 150, 215, 0) 100%) !important' : 'transparent',
    borderRadius: '20px',
    paddingLeft: !isExpanded && '0 !important',
    '&:hover': {
      background: 'linear-gradient(90deg, rgba(76, 165, 215, 0.7) 0%, rgba(76, 150, 215, 0) 100%) !important',
    },
  }
})

const MyChildListItemButton = styled(ListItemButton, { shouldForwardProp: prop => prop !== 'isExpanded' })(({ isExpanded }) => {
  return {
    paddingLeft: isExpanded ? '30px' : '0'
  }
})

const NavItem = ({ item, isExpanded }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState()
  const location = useLocation()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)

  const handleClick = useCallback(() => {
    if (item.children && item.children.length) {
      setIsOpen(prev => !prev)
    } else {
      dispatch(passRide(false))
      navigate(item.path)
    }
  }, [item, navigate])

  const handleClickChild = useCallback((path) => {
    if (path === '/logout') {
      dispatch(logout())
      return
    }
    dispatch(passRide(false))
    navigate(path)
  })

  const isActive = () => {
    if (item.path === '/shops') {
      return location.pathname === item.path
    }
    return location.pathname.includes(item.path)
  }

  return (
    <MyRootBox open={isOpen} isExpanded={isExpanded} id={item.label}>
      <MyRootListItemButton
        onClick={handleClick}
        isActive={isActive()}
        isExpanded={isExpanded}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            component="img"
            src={item.icon}
            alt="uppmodel"
            sx={{
              objectFit: 'contain',
              maxWidth: '22px',
              minHeight: '30px',
              margiTop: '4px',
              marginBottom: '4px',
            }}
          />
          {isExpanded && (
            <ListItemText
              primary={item.label === 'Profile' ? user.company.name.length > 12 ? `${user.company.name.slice(0, 12)}...` : user.company.name : item.label}
              sx={{ marginLeft: '15px' }}
            />
          )}
        </Box>
        {item.children && item.children.length > 0 && (
          <Box
            component="img"
            src={isOpen ? ArrowUp : ArrowDown}
            alt="uppmodel"
            sx={{ maxWidth: '10px' }}
          />
        )}
      </MyRootListItemButton>
      {item.children && item.children.length > 0 && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map(c => (
              <MyChildListItemButton
                key={c.path}
                onClick={() => handleClickChild(c.path)}
                isExpanded={isExpanded}
              >
                <Box
                  component="img"
                  src={c.icon}
                  alt="uppmodel"
                  sx={{
                    objectFit: 'contain',
                    maxWidth: '22px',
                    minHeight: '30px',
                    margiTop: '4px',
                    marginBottom: '4px',
                  }}
                />
                {isExpanded && (
                  <ListItemText
                    primary={c.label}
                    sx={{ marginLeft: '15px', color: c.color }}
                  />
                )}
              </MyChildListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </MyRootBox>
  )
}

export default NavItem
