import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  carts: [],
  partRequestsForCart: [],
  paymentIntent: null,
  payments: [],
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.CART_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.CART_ERROR:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_CARTS:
      newState.isLoading = false
      newState.carts = action.payload
      return newState

    case ActionTypes.SUCCESS_CREATE_CART:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_SHARE_CART:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_DELETE_PART_REQUEST_FROM_CART:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_PART_REQUEST_FOR_CART:
      newState.isLoading = false
      let copiedPartRequestsForCart = [...newState.partRequestsForCart]
      if (action.payload && !copiedPartRequestsForCart.find(partRequest => partRequest.id === action.payload.id)) {
        copiedPartRequestsForCart.push(action.payload)
      }
      newState.partRequestsForCart = copiedPartRequestsForCart
      return newState

    case ActionTypes.SUCCESS_CREATE_STRIPE:
      newState.isLoading = false
      newState.paymentIntent = action.payload
      return newState

    case ActionTypes.SUCCESS_CHECKOUT_SESSION:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_NOTIFY_CHECKOUT_STATUS:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_COMPLETE_ORDER:
    case ActionTypes.SUCCESS_ACKNOWLEDGE_ORDER:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_CREATE_PURCHASE_ORDER:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_ALL_PAYMENTS:
      newState.isLoading = false
      newState.payments = action.payload
      return newState

    case ActionTypes.SUCCESS_ADMIN_APPROVE_PO:
      newState.isLoading = false;
      return newState

    case ActionTypes.SUCCESS_APPROVE_PO:
      newState.isLoading = false
      for (const [index, cart] of state.carts.entries()) {
        // Replace the cart with a newly approved/unapproved cart
        if (cart.id === action.payload.id) {
          newState.carts[index] = action.payload
        }
      }
      return newState

    case ActionTypes.SUCCESS_UPDATE_TRACKING_NUMBER:
      newState.isLoading = false;

      newState.carts = newState.carts.map(cart =>
        cart.id === action.payload.cartId
          ? { ...cart, shippingTracking: action.payload.trackingNumber }
          : cart
      );

      return newState;

    case ActionTypes.SUCCESS_MACHINIST_CANCEL_ORDER:
      newState.isLoading = false
      console.log(action.payload.id)
      for (const [index, cart] of state.carts.entries()) {
        // Remove the now-archived cart from the state
        if (cart.id === action.payload.id) {
          console.log(newState.carts)
          newState.carts.splice(index, 1)
          console.log(newState.carts)
        }
      }
      return newState

    default:
      return state
  }
}

export default reducer
