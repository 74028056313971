import { Navigate, Outlet, useLocation } from 'react-router-dom'

const ProtectedRoutes = ({ isAuthenticated }) => {
  const { state } = useLocation()

  if (isAuthenticated) {
    return <Navigate to={state?.from || '/my-projects'} />
  }

  return <Outlet />
}

export default ProtectedRoutes
