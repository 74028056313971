import UserIcon from '../../assets/images/sidebar/user.svg'
import MyProjectsIcon from '../../assets/images/sidebar/my-projects.svg'
import MyPartsIcon from '../../assets/images/sidebar/my-parts.svg'
import MessagesIcon from '../../assets/images/sidebar/chat-read.svg'
import OrderHistoryIcon from '../../assets/images/sidebar/order-history.svg'
import QuoteRequestIcon from '../../assets/images/sidebar/quote-requests.svg'
import MyQuotesIcon from '../../assets/images/sidebar/my-quotes.svg'
import CartIcon from '../../assets/images/sidebar/shopping-cart.svg'
import AboutUsIcon from '../../assets/images/sidebar/user-remove.svg'
import ShopIcon from '../../assets/images/sidebar/shop.svg'
import GetHelpIcon from '../../assets/images/sidebar/profile-2user.svg'
import LogoutIcon from '../../assets/images/sidebar/logout.svg'
import ProfileIcon from '../../assets/images/sidebar/profile.svg'
import ApplicationIcon from '../../assets/images/sidebar/application.svg'
import ActiveOrdersIcon from '../../assets/images/sidebar/active-orders.svg'
import CompletedOrdersIcon from '../../assets/images/sidebar/completed-orders.svg'
import PaymentCardIcon from '../../assets/images/sidebar/payment-card.svg'

import { ReactComponent as RequestIcon } from '../../assets/icons/request.svg'

export const admins = [
  {
    label: 'Shop Applications',
    path: '/shop-applications',
    icon: ApplicationIcon,
  },
  // {
  //   label: 'Shop Payments',
  //   path: '/shop-payments',
  //   icon: PaymentCardIcon,
  // },
  {
    label: 'PO Tracking',
    path: '/po-tracking',
    icon: PaymentCardIcon,
  },
]

export const clients = [
  {
    label: 'My Projects',
    path: '/my-projects',
    icon: MyProjectsIcon,
  },
  // {
  //   label: 'My Parts',
  //   path: '/my-parts',
  //   icon: MyPartsIcon,
  // },
  {
    label: 'Order History',
    path: '/order-history',
    icon: OrderHistoryIcon,
  },
  {
    label: 'Cart',
    path: '/cart',
    icon: CartIcon,
  },
  {
    label: 'About Us',
    path: '/about',
    icon: AboutUsIcon,
  },
  {
    label: 'See Shops',
    path: '/shops',
    icon: ShopIcon,
  },
  {
    label: 'Get Help',
    path: '/help-center',
    icon: GetHelpIcon,
  },
]

export const machinists = [
  {
    label: 'Application',
    path: '/application',
    icon: ApplicationIcon,
  },
  {
    label: 'Quote Requests',
    path: '/quote-requests',
    icon: QuoteRequestIcon,
  },
  // {
  //   label: 'My Bid',
  //   path: '/my-bid',
  //   icon: CartIcon,
  // },
  {
    label: 'My Quotes',
    path: '/my-bid',
    icon: MyQuotesIcon,
  },
  {
    label: 'Active Orders',
    path: '/active-orders',
    icon: ActiveOrdersIcon,
  },
  {
    label: 'Completed Orders',
    path: '/completed-orders',
    icon: CompletedOrdersIcon,
  },
  {
    label: 'Get Help',
    path: '/help-center',
    icon: GetHelpIcon,
  },
  {
    label: 'About Us',
    path: '/about',
    icon: AboutUsIcon,
  },
  // {
  //   label: 'My Shop',
  //   path: '/shops',
  //   icon: ShopIcon,
  // },
]
