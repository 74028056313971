import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { persistReducer, persistStore, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from '../reducers'
import migrations, { CurrentVersion } from '../migrations'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'cart'],
  version: CurrentVersion,
  migrate: createMigrate(migrations, { debug: true }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export let store
export default function getStore() {
  store = createStore(
    persistedReducer,
    undefined,
    applyMiddleware(thunk, logger),
  );
  const persistor = persistStore(store)

  return { store, persistor }
}