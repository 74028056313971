import * as REQUESTS from './request'
import * as URL from './urls'

/**
 * request methods
 */
const { GET, POST, DELETE, PUT, PATCH } = REQUESTS

class MachineShopService {
  static createMachineShop = async data => {
    return POST(URL.CREATE_MACHINE_SHOP, { 'Content-Type': 'multi-part/form-data' }, data)
  }

  static updateMachineShop = async data => {
    return PATCH(URL.UPDATE_MACHINE_SHOP, { 'Content-Type': 'multi-part/form-data' }, data)
  }

  static getMachineShopCapabilityList = async () => {
    return GET(URL.MACHINE_SHOP_CAPABILITY_LIST)
  }

  static getMachineShopCertificationList = async () => {
    return GET(URL.MACHINE_SHOP_CERTIFICATION_LIST)
  }

  static assignMachineShopID = async (userID, machineShopID) => {
    return POST(`${URL.VERSION_URL}/user/${userID}/assign/${machineShopID}`, {}, {})
  }

  static approveMachineShop = async id => {
    return POST(`${URL.CREATE_MACHINE_SHOP}/${id}/approve`, {}, {})
  }

  static getAddressForMachineShop = async addressId => {
    return GET(`${URL.GET_ADDRESS}/${addressId}`)
  }

  static getMachineShops = async () => {
    return GET(`${URL.CREATE_MACHINE_SHOP}/admin?approved=false&pending=true`)
  }

  static getMachineshop = async id => {
    return GET(`${URL.CREATE_MACHINE_SHOP}/${id}?expand=address`)
  }

  static connectBank = async id => {
    return GET(`${URL.BANK_CONNECT}/${id}`)
  }

  static checkStatusBankConnection = async id => {
    return GET(`${URL.BANK_CONNECT}/${id}/status`)
  }

  static disconnectBank = async id => {
    return DELETE(`${URL.BANK_DISCONNECT}/${id}`)
  }

  static getMachineShopStatus = async () => {
    return GET(URL.GET_MACHINE_SHOP_STATUS)
  }
}

export default MachineShopService
