import * as ActionTypes from '../utils/actionTypes'

import Auth from '../services/auth'
import { Auth0 } from '../utils/auth0'
import { alertMsg } from '../utils/toast'

const { REACT_APP_AUTH0_AUDIENCE, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } = process.env
let axios = require('axios').default

export const authLoading = refresh => ({
  type: ActionTypes.AUTH_API_LOADING,
  payload: { refresh },
})

export const authError = error => ({
  type: ActionTypes.AUTH_API_ERROR,
  payload: error,
})

export const logout = () => ({
  type: ActionTypes.LOG_OUT,
})

export const login = (data, cb) => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const config = {
        realm: 'Username-Password-Authentication',
        username: data.email,
        password: data.password,
        audience: REACT_APP_AUTH0_AUDIENCE,
        scope: 'read:order write:order offline_access',
      }
      Auth0.client.login(config, (err, authResult) => {
        if (!err) {
          dispatch({
            type: ActionTypes.AUTH_LOGIN_SUCCESS,
            payload: {
              authResult,
              data,
            },
          })
          dispatch(getUserProfile())
          dispatch(createCompany({}))
          if (cb) {
            cb()
          }
        } else {
          alertMsg('error', err.description)
          dispatch(authError(err))
        }
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const resetPassword = (email, cb) => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const config = {
        email: email,
        connection: 'Username-Password-Authentication',
      }
      Auth0.changePassword(config, (err, authResult) => {
        if (!err) {
          dispatch({
            type: ActionTypes.AUTH_RESET_PASSWORD_SUCCESS,
          })
          cb()
        }
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const refreshAccessToken = () => {
  return async (dispatch, getState) => {
    const { token, refreshToken } = getState().auth

    if (!!token && !!refreshToken) {
      dispatch(authLoading(true))
      try {
        const options = {
          method: 'POST',
          url: `https://${REACT_APP_AUTH0_DOMAIN}/oauth/token`,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${token}`,
          },
          data: new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: REACT_APP_AUTH0_CLIENT_ID,
            refresh_token: refreshToken,
          }),
        }
        const result = await axios.request(options)

        dispatch({
          type: ActionTypes.AUTH_LOGIN_SUCCESS,
          payload: {
            authResult: {
              accessToken: result?.data.access_token,
              expiresIn: result?.data.expires_in,
              refreshToken: result?.data.refresh_token,
            },
          },
        })
      } catch (err) {
        // Error Handling - The error happens when the refresh token is expired (30 days)
        dispatch(logout())
        dispatch(authError(err))
        console.log(`Error :: ${err}`)
      }
    }
  }
}

export const register = (data, cb) => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const result = await Auth.register(data)
      dispatch({
        type: ActionTypes.AUTH_REGISTER_SUCCESS,
        payload: result,
      })
      dispatch(passRide(true))
      // alertMsg('success', 'Successfully registered!')
      // navigate('/login')
      cb()
    } catch (err) {
      cb(err?.response?.data)
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const getUserProfile = (isAssign = false) => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const result = await Auth.getUserProfile()
      dispatch({
        type: ActionTypes.SUCCESS_GET_USER,
        payload: result,
      })
      if (!isAssign) {
        // alertMsg('success', 'Successfully logged in!')
      }
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const getCompanyProfile = () => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const result = await Auth.getCompanyProfile()
      dispatch({
        type: ActionTypes.SUCCESS_GET_COMPANY,
        payload: result,
      })
      if (result && result.clientAddress && result.clientAddress.id) {
        dispatch(getClientAddress(result.clientAddress.id))
      }
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const getClientAddress = id => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const result = await Auth.getClientAddress(id)
      dispatch({
        type: ActionTypes.SUCCESS_GET_CLIENT_ADDRESS,
        payload: result,
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const updateUser = data => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const result = await Auth.updateUser(data)
      dispatch({
        type: ActionTypes.SUCCESS_UPDATE_USER,
        payload: result,
      })
      dispatch(getUserProfile())
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const updateCompany = data => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const result = await Auth.updateCompany(data)
      dispatch({
        type: ActionTypes.SUCCESS_UPDATE_COMPANY,
        payload: result,
      })
      dispatch(getCompanyProfile())
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const createCompany = data => {
  return async (dispatch, getState) => {
    dispatch(authLoading())
    try {
      const result = await Auth.createCompany(data)
      dispatch({
        type: ActionTypes.SUCCESS_CREATE_COMPANY,
        payload: result,
      })
    } catch (err) {
      console.log(`Error :: ${err}`)
      dispatch(authError(err))
    }
  }
}

export const passRide = value => ({
  type: ActionTypes.PASS_RIDE,
  payload: value,
})
