import Box from '@mui/material/Box'
import Loading from '../Loading'

const Loader = ({ loading, children, global = false }) => {
  if (loading) {
    if (global) {
      return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Loading />
        </Box>
      )  
    }
    return (
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loading />
      </Box>
    )
  }

  return <>{children}</>
}

export default Loader
