import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  projects: [],
  selectedProject: null,
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.PROJECT_API_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.PROJECT_API_ERROR:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_PROJECTS:
      newState.isLoading = false
      newState.projects = action.payload
      return newState

    case ActionTypes.SUCCESS_GET_PROJECT:
      newState.isLoading = false
      newState.selectedProject = action.payload
      return newState

    case ActionTypes.SUCCESS_CREATE_PROJECT:
      newState.isLoading = false
      newState.selectedProject = action.payload
      return newState

    case ActionTypes.SUCCESS_DELETE_PROJECT:
      newState.isLoading = false
      return newState

    default:
      return state
  }
}

export default reducer