import { toast } from 'react-toastify'

export const alertMsg = (type, message) => {
  const config = {
    // position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  if (type === 'success') {
    toast.success(message, config)
  } else if (type === 'info') {
    toast.info(message, config)
  } else if (type === 'error') {
    toast.error(message, config)
  } else if (type === 'warning') {
    toast.warning(message, config)
  }
}