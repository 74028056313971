import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  capabilities: null,
  certifications: null,
  shopApplications: null,
  selectedShopApplication: null,
  applicationForm: null,
  address: [],
  userMachineShop: null,
  status: '',
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.MACHINE_SHOP_API_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.MACHINE_SHOP_API_ERROR:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_MACHINE_SHOP_CAPABILITY_LIST:
      newState.isLoading = false
      newState.capabilities = action.payload
      return newState

    case ActionTypes.SUCCESS_MACHINE_SHOP_CERTIFICATION_LIST:
      newState.isLoading = false
      newState.certifications = action.payload
      return newState

    case ActionTypes.SUCCESS_CREATE_MACHINE_SHOP:
      newState.isLoading = false
      console.log(newState)
      return newState

    case ActionTypes.SUCCESS_ASSIGN_MACHINE_SHOP_ID:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_MACHINE_SHOPS:
      newState.isLoading = false
      newState.shopApplications = action.payload
      return newState

    case ActionTypes.SET_SELECTED_MACHINE_SHOP:
      newState.selectedShopApplication = action.payload
      return newState

    case ActionTypes.SUCCESS_APPROVE_MACHINE_SHOP:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_ADDRESS:
      newState.isLoading = false
      let copiedAddress = [...newState.address]
      if (!copiedAddress.find(item => item.id === action.payload.id)) {
        copiedAddress.push(action.payload)
      }
      newState.address = copiedAddress
      return newState

    case ActionTypes.SUCCESS_GET_MACHINE_SHOP:
    case ActionTypes.SUCCESS_UPDATE_MACHINE_SHOP:
      newState.isLoading = false
      newState.userMachineShop = action.payload
      return newState

    case ActionTypes.SUCCESS_BANK_CONNECT:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_CHECK_STATUS_BANK_CONNECTION:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_BANK_DISCONNECT:
      newState.isLoading = false
      return newState

    case ActionTypes.LOG_OUT:
      newState.userMachineShop = null
      return newState

    case ActionTypes.SUCCESS_GET_MACHINE_SHOP_STATUS:
      newState.isLoading = false
      newState.status = action.payload
      return newState

    default:
      return state
  }
}

export default reducer
