import * as REQUESTS from './request'
import * as URL from './urls'

/**
 * request methods
 */
const { GET, POST, DELETE, PUT, PATCH } = REQUESTS

class AuthService {
  static register = async (data) => {
    return POST(URL.REGISTER, {}, data)
  }

  static getUserProfile = async () => {
    return GET(URL.GET_USER_PROFILE)
  }

  static getCompanyProfile = async () => {
    return GET(`${URL.CREATE_COMPANY}/self`)
  }

  static getClientAddress = async (id) => {
    return GET(`${URL.GET_ADDRESS}/${id}`)
  }

  static updateUser = async (data) => {
    return PATCH(URL.UPDATE_USER, {}, data)
  }

  static updateCompany = async (data) => {
    return PATCH(URL.UPDATE_COMPANY, {}, data)
  }

  static createCompany = async (data) => {
    return POST(URL.CREATE_COMPANY, {}, data)
  }
}

export default AuthService