import { store } from '../store'
import { authError } from '../actions/auth'
import { Api } from '../utils/interceptor'

const GET = async (url, config, query, errLabel, responseType = 'json') => {
  try {
    const response = await Api.get(url, {
      headers: config,
      params: query,
      responseType: responseType
    })
    return Promise.resolve(response.data)
  } catch (err) {
    console.error(`Error | ${errLabel} :: ${err.message}`)
    if (err.toJSON().status === 401) store.dispatch(authError(err.toJSON()))
    return Promise.reject(err)
  }
}

const POST = async (url, config, data, query, errLabel) => {
  try {
    const response = await Api.post(url, data, {
      headers: config,
      params: query
    })
    return Promise.resolve(response.data)
  } catch (err) {
    console.error(`Error | ${errLabel} :: ${err.message}`)
    if (err.toJSON().status === 401) store.dispatch(authError(err.toJSON()))
    return Promise.reject(err)
  }
}

const PATCH = async (url, config, data, query, errLabel) => {
  try {
    const response = await Api.patch(url, data, {
      headers: config,
      params: query
    })
    return Promise.resolve(response.data)
  } catch (err) {
    console.error(`Error | ${errLabel} :: ${err.message}`)
    if (err.toJSON().status === 401) store.dispatch(authError(err.toJSON()))
    return Promise.reject(err)
  }
}

const PUT = async (url, config, data, query, errLabel) => {
  try {
    const response = await Api.put(url, data, {
      headers: config,
      params: query
    })
    return Promise.resolve(response.data)
  } catch (err) {
    console.error(`Error | ${errLabel} :: ${err.message}`)
    if (err.toJSON().status === 401) store.dispatch(authError(err.toJSON()))
    return Promise.reject(err)
  }
}

const DELETE = async (url, config, query, errLabel) => {
  try {
    const response = await Api.delete(url, {
      headers: config,
      params: query
    })
    return Promise.resolve(response.data)
  } catch (err) {
    console.error(`Error | ${errLabel} :: ${err.message}`)
    if (err.toJSON().status === 401) store.dispatch(authError(err.toJSON()))
    return Promise.reject(err)
  }
}

export {
  GET, POST, PATCH, PUT, DELETE
}