import _ from 'lodash'
import moment from 'moment'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  isRefreshLoading: false,
  isAuthenticated: false,
  token: null,
  refreshToken: null,
  expiresIn: 0,
  user: null,
  error: null,
  company: null,
  companyInfo: null,
  clientAddress: null,
  layoutRide: false,
  isUnAuthError: false,
}

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['isLoading', 'isRefreshLoading', 'isUnAuthError'],
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.AUTH_API_LOADING:
      if (action?.payload?.refresh) {
        newState.isRefreshLoading = true
      } else {
        newState.isLoading = true
      }
      return newState

    case ActionTypes.AUTH_API_ERROR:
      newState.isLoading = false
      newState.isRefreshLoading = false
      if (action.payload.status === 401) {
        newState.isUnAuthError = true
      }
      return newState

    case ActionTypes.AUTH_REGISTER_SUCCESS:
      newState.isLoading = false
      return newState

    case ActionTypes.AUTH_LOGIN_SUCCESS:
      newState.isLoading = false
      newState.isRefreshLoading = false
      newState.token = action.payload.authResult.accessToken
      newState.refreshToken = action.payload.authResult.refreshToken
      newState.expiresIn = moment()
        .add(action.payload.authResult.expiresIn - 300, 'seconds')
        .valueOf()
      newState.isUnAuthError = false
      return newState

    case ActionTypes.AUTH_RESET_PASSWORD_SUCCESS:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_USER:
      newState.isLoading = false
      newState.user = action.payload
      newState.isAuthenticated = true
      return newState

    case ActionTypes.LOG_OUT:
      newState.isLoading = false
      newState.expiresIn = 0
      newState.user = null
      newState.token = null
      newState.isAuthenticated = false
      newState.email = ''
      newState.password = ''
      newState.company = null
      newState.companyInfo = null
      newState.clientAddress = null
      return newState

    case ActionTypes.SUCCESS_CREATE_COMPANY:
      newState.isLoading = false
      newState.company = action.payload
      return newState

    case ActionTypes.AUTH_SUCCESS_CREATE_MACHINE_SHOP:
      newState.isLoading = false
      newState.user.machineShopIds = [action.payload]
      newState.user.machineShopId = action.payload
      return newState

    case ActionTypes.SUCCESS_GET_COMPANY:
      newState.isLoading = false
      newState.companyInfo = action.payload
      return newState

    case ActionTypes.SUCCESS_UPDATE_USER:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_UPDATE_COMPANY:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_CLIENT_ADDRESS:
      newState.isLoading = false
      newState.clientAddress = action.payload
      return newState

    case ActionTypes.PASS_RIDE:
      newState.layoutRide = action.payload
      return newState

    default:
      return state
  }
}

export default persistReducer(authPersistConfig, reducer)
